import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import AdminLayout from '@/layouts/AdminLayout';
import Dashboard from '@/views/admin/Dashboard';
import AuthLayout from '@/layouts/AuthLayout';
import SignIn from '@/views/auth/SignIn';
import NotFound from '@/views/NotFound';
import ChatModeration from '@/views/admin/ChatModeration';

import Streamers from '@/views/admin/streamers/Streamers';
import Users from '@/views/admin/users/Users';
import RequestDetails from '@/views/admin/streamers/RequestDetails.vue';
import UserRequestDetails from '@/views/admin/users/UserRequestDetails.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter(from, to, next) {
      checkAuth(next, 'Admin', 'SignIn', { checkAuth: 'skip' });
      // return next({ path: '/auth/sign_in' });
    },
  },
  {
    path: '/auth',
    name: 'Auth',
    component: AuthLayout,
    redirect: { name: 'SignIn' },
    beforeEnter(from, to, next) {
      if (from.params.checkAuth === 'skip') {
        return next();
      }
      checkAuth(next, 'Admin', 'SignIn', { checkAuth: 'skip' });
    },
    children: [
      {
        path: '/auth/sign_in',
        name: 'SignIn',
        component: SignIn,
      },
    ],
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminLayout,
    redirect: { name: 'Streamers' },
    beforeEnter(from, to, next) {
      if (from.params.checkAuth === 'skip') {
        return next();
      }
      checkAuth(next, 'Admin', 'SignIn', { checkAuth: 'skip' });
    },
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
      },
      {
        path: 'streamers',
        name: 'Streamers',
        component: Streamers,
      },
      {
        path: 'streamers/:id',
        name: 'StreamerRequest',
        component: RequestDetails,
      },
      {
        path: 'chat',
        name: 'ChatModeration',
        component: ChatModeration,
      },
      {
        path: 'users',
        name: 'Users',
        component: Users,
      },
      {
        path: 'users/:id',
        name: 'UserRequestDetails',
        component: UserRequestDetails,
      },
    ],
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;

function checkAuth(next, nameToSuccess, nameToError, params) {
  console.log('params', params);
  store
    .dispatch('actionGetMe')
    .then(() =>
      next({
        name: store.state.me?.id ? nameToSuccess : nameToError,
        params,
      }),
    )
    .catch(() => {
      next({ name: nameToError, params });
    });
}
