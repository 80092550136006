<template>
  <div>
    <h3>Заявки стримеров</h3>
    <b-form-checkbox
      id="showHiddenStreamers"
      v-model="checked"
      name="checkbox-1"
      @change="onChangeCheckbox"
    >
      Показать все заявки
    </b-form-checkbox>
    <b-table :fields="fields" :items="items" :busy="isBusy">
      <template #cell(user)="{ item, value }">
        <router-link :to="{ name: 'StreamerRequest', params: { id: item.id } }">
          {{ value }}
        </router-link>
      </template>
    </b-table>
    <b-pagination
      :value="currentPage"
      :total-rows="total"
      :per-page="perPage"
      class="m-auto"
      align="center"
      @change="onChangePage"
      aria-controls="my-table"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import moment from 'moment';

import { StreamerRequestCollection } from '@/api';

export default Vue.extend({
  name: 'Streamers',
  data() {
    return {
      currentPage: 1,
      fields: [
        { key: 'id' },
        {
          key: 'user',
          label: 'Имя / Ник',
          formatter(value) {
            return value.social_name
              ? `${value.name} | ${value.social_name}`
              : `${value.name}`;
          },
        },
        {
          key: 'new',
          label: 'Статус',
          formatter: (val, key, item) => {
            if (val) {
              return 'Новая заявка';
            } else return item.approved ? 'Принято' : 'Отклонено';
          },
        },
        {
          key: 'created_at',
          label: 'Создана',
          formatter(value) {
            return moment(value).format('LLLL');
          },
        },
      ],
    };
  },
  computed: {
    ...mapState(['streamersShowAll']),
    items() {
      return StreamerRequestCollection.items;
    },
    total() {
      return StreamerRequestCollection.total;
    },
    perPage() {
      return StreamerRequestCollection.perPage;
    },
    isBusy() {
      return StreamerRequestCollection.isLoading;
    },
    queryParams() {
      const params = {};
      if (!this.streamersShowAll) {
        params.new = 1;
      }
      return params;
    },
    checked: {
      get() {
        return this.streamersShowAll;
      },
      set(val) {
        this.setStreamersShowAll(val);
      },
    },
  },
  async mounted() {
    const page = Number(this.$route.query.page) || 1;
    this.changeRoute(page);
    this.updateCurrentPage(page);
    if ('show_all' in this.$route.query) {
      this.checked = true;
    }
    await this.getItems(page);
  },
  methods: {
    ...mapMutations(['setStreamersShowAll']),
    async getItems(page) {
      await StreamerRequestCollection.getPagination(page, this.queryParams);
    },
    changeRoute(page) {
      if (Number(this.$route.query.page) !== page) {
        this.$router.push({ query: { ...this.$route.query, page } });
      }
    },
    updateCurrentPage(page) {
      this.currentPage = Number(page || this.$route.query.page);
    },
    onChangePage(page) {
      this.changeRoute(page);
      this.getItems(page);
    },
    onChangeCheckbox(val) {
      const query = { ...this.$route.query };
      if (val) {
        query.show_all = null;
      } else {
        delete query.show_all;
      }
      this.$router.replace({
        name: this.$route.name,
        query,
      });
      this.getItems(1);
    },
  },
});
</script>

<style lang="scss" scoped></style>
