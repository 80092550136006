<template>
  <div>
    <b-navbar type="dark" variant="danger" class="px-3">
      <b-navbar-brand :to="{ name: 'Home' }">G-TV Admin</b-navbar-brand>
      <b-navbar-nav>
        <router-link
          :key="link.name"
          v-for="link in links"
          :to="{ name: link.name }"
          class="nav-link"
        >
          {{ link.title }}
        </router-link>
      </b-navbar-nav>
      <b-navbar-nav class="ms-auto">
        <a href="/" class="nav-link" @click="onClearToken">Выйти</a>
      </b-navbar-nav>
    </b-navbar>
    <b-container fluid>
      <div class="h-100 w-100 p-3 overflow-auto">
        <router-view />
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'AdminLayout',
  data() {
    return {
      links: [
        { title: 'Стримеры', name: 'Streamers' },
        { title: 'Модерация чата', name: 'ChatModeration' },
        { title: 'Пользователи', name: 'Users' },
      ],
    };
  },
  methods: {
    onClearToken() {
      localStorage.removeItem('token');
    },
  },
};
</script>

<style lang="scss" scoped></style>
