<template>
  <b-row>
    <b-col>
      <form @submit.prevent="onSubmit">
        <b-form-group label="Имя" class="mt-2">
          <b-input
            :value="value.name"
            required
            @input="(val) => onInput(val, 'name')"
          />
        </b-form-group>
        <b-form-group label="E-mail" class="mt-2">
          <b-input
            :value="value.email"
            type="email"
            @input="(val) => onInput(val, 'email')"
          />
        </b-form-group>
        <b-form-group label="Пароль" class="mt-2">
          <b-input
            :value="value.password"
            @input="(val) => onInput(val, 'password')"
          />
        </b-form-group>
        <b-form-group label="Телефон" class="mt-2">
          <b-input
            :value="value.phone"
            @input="(val) => onInput(val, 'phone')"
          />
        </b-form-group>
        <b-form-group label="Полное имя" class="mt-2" v-if="add">
          <b-input
            :value="value.social_name"
            @input="(val) => onInput(val, 'social_name')"
          />
        </b-form-group>
        <b-form-group label="Avatar URL" class="mt-2" v-if="add">
          <b-input
            :value="value.avatar_url"
            @input="(val) => onInput(val, 'avatar_url')"
          />
        </b-form-group>
        <b-form-group label="VkID" class="mt-2" v-if="add">
          <b-input :value="value.vkid" @input="(val) => onInput(val, 'vkid')" />
        </b-form-group>
        <b-form-group label="GoogleID" class="mt-2" v-if="add">
          <b-input
            :value="value.googleid"
            @input="(val) => onInput(val, 'googleid')"
          />
        </b-form-group>
        <b-form-group label="FacebookID" class="mt-2" v-if="add">
          <b-input
            :value="value.facebookid"
            @input="(val) => onInput(val, 'facebookid')"
          />
        </b-form-group>
        <b-form-group label="Роль" class="mt-2">
          <b-form-select
            :value="value.user_roles[0].role"
            :options="options"
            @input="(val) => onInput(val, 'user_roles', true)"
          ></b-form-select>
        </b-form-group>
        <b-form-group label="Бан" class="mt-2">
          <b-form-select
            :value="value.is_banned"
            :options="banOptions"
            required
            @input="(val) => onInput(val, 'is_banned')"
          ></b-form-select>
        </b-form-group>
        <b-row>
          <b-col class="d-flex justify-content-end">
            <b-button class="btn btn-secondary m-2 w-25" @click="onCancel">
              Отменить
            </b-button>
            <input
              class="btn btn-primary m-2 me-0"
              type="submit"
              value="Сохранить"
            />
          </b-col>
        </b-row>
      </form>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'UserDataForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
    add: {
      type: Boolean,
    },
  },
  data() {
    return {
      options: [
        { value: null, text: 'Выберите роль', disabled: true },
        { value: 'streamer', text: 'streamer' },
        { value: 'watcher', text: 'watcher' },
      ],
      banOptions: [
        { value: true, text: 'Да' },
        { value: false, text: 'Нет' },
      ],
    };
  },
  methods: {
    onInput(value, key, role) {
      role ? (this.value[key][0].role = value) : (this.value[key] = value);
      this.$emit('input', { ...this.value });
    },
    onSubmit() {
      this.$emit('submit');
    },
    onCancel() {
      this.$emit('cancel');
    },
  },
};
</script>
