import Vue from 'vue';
import request from '@/api/request';
import { add, isAfter } from 'date-fns';

export default function generateCollection(
  url,
  refreshDelaySeconds,
  isHavePagination,
  params,
) {
  const Collection = Vue.observable({
    items: [],
    isLoading: false,
    isLoadingNextPage: false,
    total: 0,
    perPage: process.env.VUE_APP_PER_PAGE,
    queryParams: { per_page: process.env.VUE_APP_PER_PAGE, ...params },
  });

  function isStartLoading() {
    if (Collection.lastDownloadItems) {
      const nextRefresh = add(Collection.lastDownloadItems, {
        seconds: refreshDelaySeconds,
      });
      if (isAfter(nextRefresh, new Date())) return false;
    }
    return true;
  }

  if (isHavePagination) {
    Collection.getPagination = function (page, params = {}) {
      return new Promise((resolve, reject) => {
        this.isLoading = true;
        request(url, {
          params: {
            ...this.queryParams,
            ...params,
            ...{ page },
          },
        })
          .then((res) => {
            this.isLoading = false;
            this.items = res.data;
            this.total = res.total;
            this.perPage = res.per_page;
            resolve(res);
          })
          .catch((err) => {
            this.isLoading = false;
            reject(err);
          });
      });
    };
  } else {
    Collection.getItems = function (params) {
      if (!isStartLoading()) return;
      this.items = [];
      this.items.length = this.queryParams.per_page;
      console.log('getItems');
      this.isLoading = true;
      request(url, { params: { ...params } })
        .then((res) => {
          this.lastDownloadItems = new Date();
          this.isLoading = false;
          this.items = res;
        })
        .catch((err) => {
          console.error('err', err); // TODO add error handler
        });
    };
  }

  Collection.setParams = function (params, refresh) {
    Object.assign(this.queryParams, params);
    if (refresh) {
      this.getItems({}, true);
    }
  };

  return Collection;
}
