import request from '@/api/request';
import './Collection';
import generateCollection from '@/api/Collection';
// Admin

const { VUE_APP_PER_PAGE } = process.env;

/** @type {StreamerRequestCollectionType} */
const StreamerRequestCollection = generateCollection(
  '/api/admin/streamer-requests',
  600,
  true,
  {
    per_page: VUE_APP_PER_PAGE,
    sort_by: 'id',
    sort_order: 'asc',
  },
);
const ChatReportsRequestCollection = generateCollection(
  '/api/admin/chat-reports',
  600,
  true,
  {
    per_page: VUE_APP_PER_PAGE,
  },
);
const UsersRequestCollection = generateCollection(
  '/api/admin/users',
  600,
  true,
  {
    per_page: VUE_APP_PER_PAGE,
    sort_by: 'id',
  },
);

// Games
export function getGames(params) {
  return request('/api/games', { params });
}

export function getGame(id) {
  return request(`/api/games/${id}`);
}

// Channels
export function getChannels(params) {
  return request('/api/channels', { params });
}

export function getChannel(id) {
  return request(`/api/channels/${id}`);
}

export function updateStreamerRequest(userId, approved) {
  return request(`/api/admin/streamer-requests/${userId}`, {
    method: 'PUT',
    data: { approved },
  });
}
export function updateUserRequest(userId, newData) {
  return request(`/api/admin/users/${userId}`, {
    method: 'PUT',
    data: newData,
  });
}
export function addUserRequest(data) {
  return request(`/api/admin/users/`, {
    method: 'POST',
    data: data,
  });
}
export function updateChatReportRequest(
  chatReportId,
  content_comment_id,
  comment_deleted,
) {
  return request(`/api/admin/chat-reports/${chatReportId}`, {
    method: 'PUT',
    data: { content_comment_id, comment_deleted },
  });
}

export {
  StreamerRequestCollection,
  ChatReportsRequestCollection,
  UsersRequestCollection,
};
