import request from '@/api/request';

export function actionSignIn(data) {
  return request('api/auth/login', { method: 'POST', data });
}

export function actionGetMe() {
  return request('api/auth/me', { method: 'POST' });
}

/**
 * @param {string | number} streamer_request_id
 * @return Promise<ResStreamerRequest>
 * */
export function getStreamerRequest(streamer_request_id) {
  return request(`api/admin/streamer-requests/${streamer_request_id}`, {
    method: 'GET',
  });
}
export function getUserRequest(user_request_id) {
  return request(`api/admin/users/${user_request_id}`, {
    method: 'GET',
  });
}
