<template>
  <div>
    <h3>404 NotFound</h3>
    <router-link :to="{ name: 'Home' }">Перейти на главную</router-link>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style lang="scss" scoped></style>
