import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_API_URL,
});

export default function (url, { method = 'GET', data, params } = {}) {
  const token = localStorage.getItem('token');
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };
  return new Promise((resolve, reject) => {
    axiosInstance(url, { method, data, params, headers })
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => reject(err));
  });
}

export function getFile(filePath) {
  return new Promise((resolve, reject) => {
    return axios({
      url: `${process.env.VUE_APP_BASE_API_URL}${filePath}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'file.jpeg');
      document.body.appendChild(link);
      link.click();
      resolve(true)
    }).catch(err => reject(err));
  })
}
