<template>
  <b-form-group :label="label">
    <b-input :value="value" @input="onInput"></b-input>
  </b-form-group>
</template>

<script>
export default {
  name: 'InputSearch',
  props: {
    label: {
      type: String,
      default: 'Поиск',
    },
    value: {
      type: String,
    },
    delay: {
      type: Number,
      default: 300,
    },
  },
  data() {
    return {
      delayTimeout: null,
    };
  },
  methods: {
    onInput(val) {
      if (this.delayTimeout) clearTimeout(this.delayTimeout);
      this.delayTimeout = setTimeout(() => {
        this.$emit('input', val);
      }, this.delay);
    },
  },
};
</script>
