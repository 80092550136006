<template>
  <b-row>
    <b-col md="6" sm="12" class="mt-3">
      <h4>Заявка</h4>
      <b-card v-if="request">
        <div>
          <small><b>Создана:</b></small>
          <span v-if="request" class="mx-lg-1">
            {{ moment(request.created_at).format('LLLL') }}
          </span>
        </div>
        <div>
          <h6 class="text-center mt-3 mb-3">Файлы</h6>
          <div
            v-for="(file, index) in request.files"
            :key="index"
            class="file pointer p-1"
            :class="{ 'file-loading': file.isLoading }"
            @click="onClickFile(file)"
          >
            <b-spinner
              v-if="file.isLoading"
              class="file__spinner"
              small
              variant="primary"
            ></b-spinner>
            <div class="file__link">{{ file.path }}</div>
          </div>
        </div>
      </b-card>
    </b-col>
    <b-col md="6" sm="12" class="mt-3">
      <h4>Пользователь</h4>
      <b-card v-if="request">
        <div>
          <small><b>Имя:</b></small>
          <span class="mx-lg-1">{{ request.user.name }}</span>
        </div>
        <div>
          <small><b>Ник:</b></small>
          <span class="mx-lg-1">
            {{ request.user.social_name }}
          </span>
        </div>
        <div>
          <small><b>Email:</b></small>
          <span class="mx-lg-1">
            {{ request.user.email }}
          </span>
        </div>
        <div>
          <small><b>Телефон:</b></small>
          <span class="mx-lg-1">
            {{ request.user.phone }}
          </span>
        </div>
      </b-card>
      <b-spinner v-else variant="primary"></b-spinner>
    </b-col>
    <b-col>
      <b-row class="mt-4">
        <b-col v-if="request.new" md="12" class="text-center">
          <b-button
            variant="outline-danger"
            class="mx-2"
            @click="onStreamerReject()"
          >
            Reject
          </b-button>
          <b-button
            variant="outline-success"
            class="mx-2"
            @click="onStreamerApprove()"
          >
            Approve
          </b-button>
        </b-col>
        <b-col v-if="!request.new" md="12" class="text-center">
          <span v-if="request.approved">
            По данной заявке вынесено решение: Принять
          </span>
          <span v-else>По данной заявке вынесено решение: Отклонить</span>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';

import { getStreamerRequest } from '@/api/actions';
import { getFile } from '@/api/request';
import { updateStreamerRequest } from '@/api';

export default Vue.extend({
  name: 'RequestDetails',
  data() {
    return {
      request: '',
    };
  },
  mounted() {
    const { id } = this.$route.params;
    getStreamerRequest(id)
      .then((response) => {
        this.request = response;
      })
      .catch((err) => {
        console.error(err);
      });
  },
  methods: {
    moment,
    async onClickFile(file) {
      if (file.isLoading) return;
      try {
        this.$set(file, 'isLoading', true);
        await getFile(file.path);
      } catch (e) {
        console.error(e);
      } finally {
        file.isLoading = false;
      }
    },
    onStreamerReject() {
      const { id } = this.$route.params;
      updateStreamerRequest(id, false).then(() => {
        this.request.new = false;
      });
    },
    onStreamerApprove() {
      const { id } = this.$route.params;
      updateStreamerRequest(id, true).then(() => {
        this.request.new = false;
      });
    },
  },
});
</script>
<style lang="scss">
.file {
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    transition: all linear 0.1s;
  }
  position: relative;
  .file__spinner {
    position: absolute;
    left: 10px;
    top: 10px;
  }
  .file__link {
    margin-left: 35px;
  }
  &.file-loading {
    color: silver;
  }
}
</style>
