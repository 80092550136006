import Vue from 'vue';
import Vuex from 'vuex';
import { actionGetMe } from '@/api/actions';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    me: undefined,
    streamersShowAll: false,
    chatReportsShowAll: false,
  },
  mutations: {
    setMe(state, payload) {
      state.me = payload;
    },
    setStreamersShowAll(state, payload) {
      state.streamersShowAll = payload;
    },
    setChatReportsShowAll(state, payload) {
      state.chatReportsShowAll = payload;
    },
  },
  actions: {
    actionGetMe({ commit }) {
      return new Promise((resolve, reject) => {
        actionGetMe()
          .then((user) => {
            commit('setMe', user);
            resolve(user);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  modules: {},
});
