<template>
  <div>
    <h3>Модерация чата</h3>
    <b-form-checkbox
      id="showHiddenStreamers"
      v-model="checked"
      name="checkbox-1"
      @change="onChangeCheckbox"
    >
      Показать все сообщения
    </b-form-checkbox>
    <b-table :fields="fields" :items="items">
      <template #cell(action)="{ item }">
        <b-button
          v-if="item.new"
          variant="outline-danger"
          class="mx-2"
          @click="onChatReportDelete(item.id)"
        >
          Delete
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
import { updateChatReportRequest } from '@/api';
import { ChatReportsRequestCollection } from '@/api';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'ChatModeration',
  data() {
    return {
      currentPage: 1,
      fields: [
        {
          key: 'id',
        },
        {
          key: 'text',
          label: 'Сообщение',
        },
        {
          key: 'comment.text',
          label: 'Комментарий',
        },
        {
          key: 'action',
          label: 'Действие',
        },
      ],
    };
  },
  computed: {
    ...mapState(['chatReportsShowAll']),
    items() {
      return ChatReportsRequestCollection.items;
    },
    queryParams() {
      const params = {};
      if (!this.chatReportsShowAll) {
        params.new = 1;
      }
      return params;
    },
    checked: {
      get() {
        return this.chatReportsShowAll;
      },
      set(val) {
        this.setChatReportsShowAll(val);
      },
    },
  },
  async mounted() {
    const page = Number(this.$route.query.page) || 1;
    this.changeRoute(page);
    this.updateCurrentPage(page);
    if ('show_all' in this.$route.query) {
      this.checked = true;
    }
    await this.getItems(page);
  },
  methods: {
    ...mapMutations(['setChatReportsShowAll']),
    changeRoute(page) {
      if (Number(this.$route.query.page) !== page) {
        this.$router.push({ query: { ...this.$route.query, page } });
      }
    },
    updateCurrentPage(page) {
      this.currentPage = Number(page || this.$route.query.page);
    },
    async getItems(page) {
      await ChatReportsRequestCollection.getPagination(page, this.queryParams);
    },
    onChangePage(page) {
      this.changeRoute(page);
      this.getItems(page);
    },
    onChangeCheckbox(val) {
      const query = { ...this.$route.query };
      if (val) {
        query.show_all = null;
      } else {
        delete query.show_all;
      }
      this.$router.replace({
        name: this.$route.name,
        query,
      });
      this.getItems(1);
    },
    onChatReportDelete(chatReportId) {
      updateChatReportRequest(chatReportId, true, true).then(() => {
        this.getItems(1);
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
