<template>
  <b-row>
    <b-col md="6" sm="12" class="mt-3">
      <h4>Пользователь</h4>
      <b-row></b-row>
      <b-card
        class="user-avatar w-100"
        v-if="model"
        :img-src="model.avatar_url"
      >
        <div>
          <small><b>ID:</b></small>
          <span v-if="model" class="mx-lg-1">{{ model.id }}</span>
        </div>
        <div>
          <small><b>Имя:</b></small>
          <span v-if="model" class="mx-lg-1">{{ model.name }}</span>
        </div>
        <div>
          <small><b>E-mail:</b></small>
          <span v-if="model" class="mx-lg-1">
            {{ model.email }}
          </span>
        </div>
        <div>
          <small><b>Роль:</b></small>
          <span v-if="model.user_roles[0]" class="mx-lg-1">
            {{ model.user_roles[0].role }}
          </span>
        </div>
        <div>
          <small><b>Бан:</b></small>
          <span class="mx-lg-1">
            {{ model.is_banned ? 'Да' : 'Нет' }}
          </span>
        </div>
        <div>
          <small><b>Телефон:</b></small>
          <span class="mx-lg-1">
            {{ model.phone }}
          </span>
        </div>
        <b-button class="mt-2" v-if="edit != true" @click="onEditUser">
          Редактировать
        </b-button>
      </b-card>
      <b-card v-if="edit" class="mt-4">
        <UserDataForm
          v-model="editModel"
          @submit="onSubmit"
          @cancel="onCancel"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import { getUserRequest } from '@/api/actions';
import { updateUserRequest } from '@/api';
import UserDataForm from '@/components/base/UserDataForm';

export default Vue.extend({
  name: 'UserRequestDetails',
  components: { UserDataForm },
  data() {
    return {
      inputModel: null,
      model: null,
      editModel: null,
      edit: false,
      selected: null,
      options: [
        { value: null, text: 'Выберите роль', disabled: true },
        { value: 'streamer', text: 'streamer' },
        { value: 'watcher', text: 'watcher' },
      ],
      banOptions: [
        { value: true, text: 'Да' },
        { value: false, text: 'Нет' },
      ],
    };
  },
  mounted() {
    const { id } = this.$route.params;
    getUserRequest(id)
      .then((response) => {
        this.model = response;
        this.editModel = JSON.parse(JSON.stringify(response));
        this.checkUserRole();
      })
      .catch((err) => {
        console.error(err);
      });
  },
  methods: {
    moment,
    onSubmit() {
      this.onSave();
    },
    onCancel() {
      this.onEditUser();
    },
    onSave() {
      this.$bvModal
        .msgBoxConfirm('Вы уверены, что хотите сохранить изменения?', {
          title: 'Внесение изменений',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Да',
          cancelTitle: 'Нет',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.onModalConfirm();
          }
        });
    },
    checkUserRole() {
      if (this.editModel.user_roles[0]) {
        return;
      } else {
        this.editModel.user_roles.push({
          id: 0,
          role: false,
          user_id: this.editModel.id,
        });
      }
    },
    onEditUser() {
      this.edit = !this.edit;
    },
    onModalConfirm() {
      console.log(this.editModel);
      const { id } = this.$route.params;
      this.edit = !this.edit;
      if (this.editModel.user_roles[0].role) {
        this.editModel.role = this.editModel.user_roles[0].role;
        delete this.editModel.user_roles;
      } else {
        delete this.editModel.user_roles;
      }
      delete this.editModel.description;
      delete this.editModel.social_name;
      updateUserRequest(id, { ...this.editModel })
        .then(() => {
          getUserRequest(id).then((response) => {
            this.model = response;
            this.editModel = JSON.parse(JSON.stringify(response));
            this.checkUserRole();
            console.log('request', this.editModel);
          });
        })
        .catch(() => {
          this.editModel = JSON.parse(JSON.stringify(this.model));
          this.checkUserRole();
        });
    },
  },
});
</script>
<style lang="scss">
.user-avatar img {
  width: 15%;
  margin: 1em;
}
.file {
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
    transition: all linear 0.1s;
  }
}
</style>
