<template>
  <div>
    <b-row>
      <b-col>
        <h3>Пользователи</h3>
      </b-col>
      <b-col class="d-flex justify-content-end">
        <b-button
          v-b-modal.addUserModal
          variant="primary"
          class=".justify-content-end"
          @click="
            () => {
              showModal = true;
            }
          "
        >
          Добавить пользователя
        </b-button>
      </b-col>
    </b-row>

    <div>
      <b-modal
        v-if="showModal"
        id="addUserModal"
        title="Добавление нового пользователя"
        hide-footer
      >
        <UserDataForm
          v-model="addModel"
          @submit="onSubmit"
          @cancel="onCancel"
          :add="true"
        />
      </b-modal>
    </div>

    <InputSearch v-model="inputSearch" />
    <b-table :fields="fields" :items="items" :busy="isBusy">
      <template #cell(name)="{ item, value }">
        <router-link
          :to="{ name: 'UserRequestDetails', params: { id: item.id } }"
        >
          {{ value }}
        </router-link>
      </template>
    </b-table>
    <b-pagination
      :value="currentPage"
      :total-rows="total"
      :per-page="perPage"
      class="m-auto"
      align="center"
      @change="onChangePage"
      aria-controls="my-table"
    />
  </div>
</template>

<script>
import { addUserRequest } from '@/api';
import InputSearch from '@/components/base/InputSearch.vue';
import Vue from 'vue';
import UserDataForm from '@/components/base/UserDataForm';
import { UsersRequestCollection } from '@/api';

export default Vue.extend({
  name: 'Users',
  components: { InputSearch, UserDataForm },
  data() {
    return {
      inputSearch: '',
      addModel: {
        user_roles: [{}],
      },
      inputSearchDelayTimeout: null,
      currentPage: 1,
      showModal: true,
      fields: [
        { key: 'id' },
        {
          key: 'name',
          label: 'Имя',
        },
        {
          key: 'email',
          label: 'E-mail',
        },
        {
          key: 'user_roles',
          label: 'Роль',
          formatter(val) {
            return val[0]?.role;
          },
        },
      ],
    };
  },
  computed: {
    items() {
      return UsersRequestCollection.items;
    },
    total() {
      return UsersRequestCollection.total;
    },
    perPage() {
      return UsersRequestCollection.perPage;
    },
    isBusy() {
      return UsersRequestCollection.isLoading;
    },
  },
  async mounted() {
    const page = Number(this.$route.query.page) || 1;
    this.changeRoute(page);
    this.updateCurrentPage(page);
    await this.getItems(page);
  },
  methods: {
    async getItems(page, searchValue) {
      searchValue
        ? await UsersRequestCollection.getPagination(page, {
            search: searchValue,
          })
        : await UsersRequestCollection.getPagination(page);
    },
    changeRoute(page) {
      if (Number(this.$route.query.page) !== page) {
        this.$router.push({ query: { ...this.$route.query, page } });
      }
    },
    updateCurrentPage(page) {
      this.currentPage = Number(page || this.$route.query.page);
    },
    onChangePage(page) {
      this.changeRoute(page);
      this.getItems(page);
    },
    onCancel() {
      this.showModal = !this.showModal;
    },
    onSubmit() {
      this.addModel.role = this.addModel.user_roles[0].role;
      delete this.addModel.user_roles;
      console.log(this.addModel);
      this.onSave();
    },
    onSave() {
      this.$bvModal
        .msgBoxConfirm('Вы уверены, что хотите сохранить изменения?', {
          title: 'Внесение изменений',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Да',
          cancelTitle: 'Нет',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.onModalConfirm();
          }
        });
    },
    onModalConfirm() {
      this.showModal = !this.showModal;
      addUserRequest({
        ...this.addModel,
      }).finally(() => {
        this.addModel = {};
        this.addModel.user_roles = [{}];
      });
    },
  },
  watch: {
    inputSearch(searchValue) {
      this.$router.push({ query: { ...this.$route.query, searchValue } });
      this.getItems(1, searchValue);
    },
  },
});
</script>

<style lang="scss" scoped></style>
