<template>
  <div>
    <b-row class="mt-5">
      <b-col md="4" offset-md="4">
        <b-card>
          <h3>Авторизоваться</h3>
          <b-form @submit.prevent="onSubmit">
            <b-form-group label="Электронная почта">
              <b-input v-model="email"></b-input>
            </b-form-group>
            <b-form-group label="Пароль">
              <b-input type="password" v-model="password"></b-input>
            </b-form-group>
            <b-button type="submit" class="mt-2" variant="primary">
              Авторизоваться
            </b-button>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { actionSignIn } from '@/api/actions';

export default {
  name: 'SignIn',
  data() {
    return {
      email: '',
      password: '',
    };
  },
  methods: {
    ...mapActions(['actionGetMe']),
    async onSubmit() {
      try {
        const { access_token } = await actionSignIn(this.$data);
        localStorage.setItem('token', access_token);
        await this.actionGetMe();
        this.$router.push({ name: 'Admin', params: { checkAuth: 'skip' } });
      } catch (e) {
        console.log('err', e);
        localStorage.removeItem('token');
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
